import React from 'react'
import './proyectos.css';
import Carousel from './Carousel.js';

export default function Proyectos() {

return(
<div id="proyectos">
  <div className="titulo">Galería de Proyectos</div>
  <div className="carousel">
 	<Carousel/>
  </div>
</div>
)}